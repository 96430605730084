import { css } from 'styled-components';

export const GlobalColors = css`
  --light-aqua: #a7ded9;
  --pinkish-grey: #ceafaf;
  --brownish-pink: #a78a8a;
  --dark-blue-green: #00524d;
  --darkish-pink: #e0457b;
  --melon: #e37070;
  --light-pink: #ebe5e5;
  --very-light-pink: #f8f7f6;
  --light-grey-blue: #9fc1be;
  --dark-red: #6f0000;
  --sandy: #e7cf83;
  --black: #000000;
  --white: #ffffff;
`;
