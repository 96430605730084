import { createGlobalStyle } from "styled-components"

import * as normalize from "normalize.css"
import "./fonts.css"
import { GlobalColors } from "./colors"

const Index = createGlobalStyle`
  ${normalize};

  :root {
    ${GlobalColors}
  }

  * {
    box-sizing: border-box;
  }

  body {
    font-family: 'AvenirArabic', sans-serif;
    font-size: 12px;

    &.fixed {
      overflow: hidden;
    }
  }

  img {
    max-width: 100%;
  }
  
  #root {
    min-height: 100vh;
    min-width: 100vh;
    display: flex;
    flex-direction: column;
  }

  h2 {
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: 0.95px;
  }
  
  ul, ol {
    padding: 1.5em!important;
    li {
      line-height: 1.5;
    }
  }
`

export default Index
