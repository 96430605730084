import React from "react"
import styled from 'styled-components';

import GlobalStyles from './styles/global';

import { StyledMainContent } from './styles/layout';

const Layout = ({ children, rtl }) => (
  <StyledLayout data-rtl={rtl}>
    <GlobalStyles />
    <StyledMainContent>
      <main>{children}</main>
    </StyledMainContent>
  </StyledLayout>
);

const StyledLayout = styled.div`
  &[data-rtl="true"] {
    direction: rtl;
  }
`;

export default Layout
